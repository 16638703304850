<template>
  <div class="swiper-container" @touchstart="audioPlay">
    <div
      :class="['audio-btn', musicPlay ? 'rotate' : '']"
      @click="controlMusic"
    >
      <audio
        loop
        src="https://img.lnomick.com/audio/3.mp3"
        class="myaudio"
        autoplay
        preload
      ></audio>
    </div>
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/5.jpg" />
        <div class="pull-down">
          <img src="https://img.lnomick.com/ls/4-14/pull-down.png" />
        </div>
      </div>
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/3.jpg" />
        <div class="pull-down">
          <img src="https://img.lnomick.com/ls/4-14/pull-down.png" />
        </div>
      </div>
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/1.jpg" />
        <div class="pull-down">
          <img src="https://img.lnomick.com/ls/4-14/pull-down.png" />
        </div>
      </div>
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/4.jpg" />
        <div class="pull-down">
          <img src="https://img.lnomick.com/ls/4-14/pull-down.png" />
        </div>
      </div>
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/6.jpg" />
        <div class="pull-down">
          <img src="https://img.lnomick.com/ls/4-14/pull-down.png" />
        </div>
      </div>
      <div class="swiper-slide">
        <img src="https://img.lnomick.com/ls/4-14/2.jpg" />
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { onMounted, ref, reactive, nextTick, onBeforeUnmount } from "vue";
import axios from "axios";
import wx from "weixin-js-sdk";
import qs from "qs";
export default {
  setup() {
    const swData = reactive({
      swiper: {}
    })
    const musicPlay = ref(false);
    const jingyin = ref(false);
    const weChatData = reactive({
      appid: "",
      noncestr: "",
      timestamp: 0,
      signature: "",
      url: "",
    });
    const controlMusic = () => {
      const myAuto = document.getElementsByClassName("myaudio")[0];
      if (musicPlay.value == true) {
        myAuto.pause();
        musicPlay.value = false;
        jingyin.value = true;
      } else {
        myAuto
          .play()
          .then((success) => {
            musicPlay.value = true;
            jingyin.value = false;
          })
          .catch((e) => {
            musicPlay.value = false;
            jingyin.value = true;
          });
      }
    };

    const getWeChatData = async () => {
      const postData = {
        url: location.href,
      };
      try {
        var res = await axios({
          url: "https://api.lnomick.com/index.php/api/Wechat3",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(postData),
        });
        if (res.data.code == "2000") {
          weChatData.appid = res.data.data.appid;
          weChatData.noncestr = res.data.data.noncestr;
          weChatData.timestamp = res.data.data.timestamp;
          weChatData.signature = res.data.data.signature;
          weChatData.url = res.data.data.url;
          weChatApi();
        } else {
          console.error("请求错误");
        }
      } catch (err) {
        console.error(err);
      }
    };
    const weChatApi = () => {
      var appId = weChatData?.appid;
      var noncestr = weChatData?.noncestr;
      var timestamp = weChatData?.timestamp;
      var signature = weChatData?.signature;
      var url = weChatData?.url;

      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: noncestr,
        signature: signature,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          success: function (res) {
            console.log(res);
          },
        });
        //分享给朋友
        wx.updateAppMessageShareData({
          title: "战疫记，——辽沈晚报在行动！", // 分享标题
          desc: "", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享给朋友成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        //分享到朋友圈
        wx.updateTimelineShareData({
          title: "战疫记，——辽沈晚报在行动！", // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享到朋友圈成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        wx.error(function (res) {
          console.log("res", res);
        });
      });
    };
    const autoMusicPlay = () => {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          WeixinJSBridge.invoke("getNetworkType", {}, function (e) {
            network = e.err_msg.split(":")[1];
            audioPlay();
          });
        },
        false
      );
    };
    const audioPlay = () => {
      if (musicPlay.value == false && jingyin.value == false) {
        console.log("准备播放音乐");
        const myAuto = document.getElementsByClassName("myaudio")[0];
        myAuto
          .play()
          .then((success) => {
            musicPlay.value = true;
          })
          .catch((e) => {
            console.log("播放音频失败");
          });
      } else {
        console.log("正在播放");
      }

      /*
      
        */
    };
    const newSwi = () => {
      nextTick(() => {
       swData.swiper = new Swiper(".swiper-container", {
          observer: true,
          observeParents: true,
          direction: "vertical", // 垂直切换选项
        });
      });
    };

    onMounted(() => {
      newSwi();
      document.title = "战疫记，——辽沈晚报在行动！";
      getWeChatData();
      autoMusicPlay();
    });
    onBeforeUnmount(()=>{
      swData.swiper.destroy()
    })
    return {
      controlMusic,
      musicPlay,
      audioPlay,
      newSwi
    };
  },
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotating {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}




@keyframes pull {
  0% {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0);
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .swiper-wrapper{
    width: 100%;

  }
  .swiper-slide {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .audio-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background: url("https://img.lnomick.com/ls/audio.png") center center
      no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    
  }
  img {
    width: 100%;
    height: 100%;
  }
  .rotate {
    -webkit-animation: rotating 1.2s linear infinite;
    -moz-animation: rotating 1.2s linear infinite;
    -o-animation: rotating 1.2s linear infinite;
    animation: rotating 1.2s linear infinite;
  }
}
.pull-down {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  height: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  animation: pull 2s ease-in-out infinite;
  img{
      width: 3rem;
      height: 3rem;
  }
}
</style>