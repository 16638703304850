<template>
  <div class="bg" v-show="!sData.haibaoShow">
    <div class="lslogo">
      <img src="https://img.lnomick.com/ls/lslogo3.png">
    </div>
    <div class="center">
      <div class="top">
        <div class="headimg">
          <img :src="sData.headimgurl" />
        </div>
        <div class="nickname">{{ sData.nickname }}</div>
        <div class="top-title">疫情结束后，我最想做的三件事：</div>
        <div class="top-title-e">The world is always full of hope.</div>

        <div class="selectList">
          <div
            class="select-item"
            v-for="(item, index) in sData.selectList"
            :key="index"
          >
            <img :src="item.imgUrl" />
          </div>
        </div>
      </div>
      <div class="con">
        <div class="tips">点击按钮生成你的专属海报<br>长按转发/保存海报与你的朋友分享</div>
        <div class="displayHaibao"  @click="displayHaibao">
          <img src="https://img.lnomick.com/ls/want-btn2.png">
        </div>
        <div class="displayHaibao" @click="reSelect"><img src="https://img.lnomick.com/ls/want-btn1.png"></div>
      </div>
    </div>
    <div class="au-info">
      技术：李名 <span></span> 美编：王可 <span></span>审核：吕兴琳
    </div>

  </div>
  <div class="share" v-show="sData.haibaoShow">
    <!-- 海报html元素 -->
    <div ref="HaiBao" id="posterHtml" v-show="sData.haibaoUrl == ''">
      <div class="hb-top">
        <div class="hb-headimg">
          <img :src="sData.headimgurl" />
        </div>
        <div class="hb-nickname">{{ sData.nickname }}</div>
        <div class="hb-title">疫情结束后,我想做的三件事:</div>
        <div class="hb-title2">The world is always full of hope.</div>
      </div>
      <div class="hb-selectList">
        <div
          class="hb-select-item"
          v-for="(item, index) in sData.selectList"
          :key="index"
        >
          <img :src="item.imgUrl" />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="hb-b">
        <div class="hb-b-l">
          <div class="hb-b-tips">
            将此图发送给好友/群/朋友圈<br />长按保存/分享海报
          </div>
          <div class="hb-b-logo">
            <img src="../assets/images/lslogo3.png" />
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="hb-b-r">
          <!-- 二维码 -->
          <div id="qrcodeImg"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="haibaoImg" v-show="sData.haibaoUrl != ''">
      <img :src="sData.haibaoUrl" />
    </div>

    <div class="btn-group">
      <div class="closeHaibao" @click="closeHaibao" v-show="sData.haibaoStatus">
        关闭
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import axios from "axios";
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
import { onMounted, reactive, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  setup() {
    const Route = useRoute();
    const router = useRouter();
    const sData = reactive({
      openid: "",
      imgList: [
        {
          index: 0,
          imgUrl: require("@/assets/images/want1.jpg"),
        },
        {
          index: 1,
          imgUrl: require("@/assets/images/want2.jpg"),
        },
        {
          index: 2,
          imgUrl: require("@/assets/images/want3.jpg"),
        },
        {
          index: 3,
          imgUrl: require("@/assets/images/want4.jpg"),
        },
        {
          index: 4,
          imgUrl: require("@/assets/images/want5.jpg"),
        },
        {
          index: 5,
          imgUrl: require("@/assets/images/want6.jpg"),
        },
        {
          index: 6,
          imgUrl: require("@/assets/images/want7.jpg"),
        },
        {
          index: 7,
          imgUrl: require("@/assets/images/want8.jpg"),
        },
        {
          index: 8,
          imgUrl: require("@/assets/images/want9.jpg"),
        },
        {
          index: 9,
          imgUrl: require("@/assets/images/want10.jpg"),
        },
        {
          index: 10,
          imgUrl: require("@/assets/images/want11.jpg"),
        },
        {
          index: 11,
          imgUrl: require("@/assets/images/want12.jpg"),
        },
        {
          index: 12,
          imgUrl: require("@/assets/images/want13.jpg"),
        },
        {
          index: 13,
          imgUrl: require("@/assets/images/want14.jpg"),
        },
        {
          index: 14,
          imgUrl: require("@/assets/images/want15.jpg"),
        },
      ],
      selectList: [],
      nickname: "",
      haibaoShow: false,
      headimgurl: "",
      haibaoHeight: 800,
      haibaoUrl: "",
      posterImg: "", // 最终生成的海报图片
      posterImgName: "我的海报", // 最终生成的海报图片名称
      haibaoStatus: false,
    });
    const weChatData = reactive({
      appid: "",
      noncestr: "",
      timestamp: 0,
      signature: "",
      url: "",
    });
    const getSelect = async () => {
      const postData = {
        openid: sData.openid,
      };
      try {
        const result = await axios({
          url: "https://api.lnomick.com/index.php/api/getWangSelect",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(postData),
        });
        if (result.data.code == "2000") {
          sData.selectList = [
            {
              index: result.data.data.select1 * 1,
              imgUrl: sData.imgList[result.data.data.select1]?.imgUrl,
            },
            {
              index: result.data.data.select2 * 1,
              imgUrl: sData.imgList[result.data.data.select2]?.imgUrl,
            },
            {
              index: result.data.data.select3 * 1,
              imgUrl: sData.imgList[result.data.data.select3]?.imgUrl,
            },
          ];

          //sData.selectList = a
          //console.log(sData.selectList)

          sData.nickname = result.data.data.nickname;
          sData.headimgurl = result.data.data.headimgurl;
        }
      } catch (error) {
        alert(error);
      }
    };
    const createQrcode = (text) => {
      // 生成二维码
      const qrcodeImgEl = document.getElementById("qrcodeImg");
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 80,
        height: 80,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(text);
    };
    const getBase64Image = (img) => {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let dataURL = canvas.toDataURL("image/jpeg"); // 可选其他值 image/jpeg

      return dataURL;
    };

    const getPoster = () => {
      let htmlContainer = document.getElementById("posterHtml"); // 需要截图的包裹的（原生的）DOM 对象
      let width = htmlContainer.offsetWidth; // 获取dom 宽度
      let height = htmlContainer.offsetHeight; // 获取dom 高度
      let canvas = document.createElement("canvas"); // 创建一个canvas节点
      let scale = 2; // 定义任意放大倍数 支持小数

      canvas.width = width * scale; // 定义canvas 宽度 * 缩放
      canvas.height = height * scale; // 定义canvas高度 *缩放
      canvas.getContext("2d").scale(scale, scale); // 获取context,设置scale

      // http://html2canvas.hertzen.com/configuration/   配置设置地址
      let opts = {
        scale: scale, // 添加的scale 参数
        canvas: canvas, // 自定义 canvas
        // logging: true, // 日志开关，便于查看html2canvas的内部执行流程
        width: width, // dom原始宽度
        height: height,
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true, // 【重要】开启画布污染
        backgroundColor: "#fff",
      };

      html2canvas(htmlContainer, opts).then((canvas) => {
        let context = canvas.getContext("2d");

        // 关闭抗锯齿
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        // 默认转化的格式为png，也可设置为其他格式
        let posterUrl = canvas.toDataURL("image/jpeg");
        imgDown(posterUrl);
        console.log(posterUrl);
      });
    };

    const createPoster = () => {
      //click 点击按钮生成海报
      // 生成海报

      const domObj = document.getElementById("posterHtml");
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        scale: window.devicePixelRatio,
        onclone(doc) {
          let e = doc.querySelector("#posterHtml");
          e.style.display = "block";
        },
      }).then((canvas) => {
        // 在微信里,可长按保存或转发
        let posterImg = canvas.toDataURL("image/png");

        sData.haibaoUrl = posterImg;
        Toast.loading({
          message: "海报生成完毕，长按保存转发",
          forbidClick: true,
        });
        sData.haibaoStatus = true;

        //imgDown(posterImg);
      });
    };
    const imgDown = (img) => {
      var alink = document.createElement("a");
      alink.href = img;
      alink.download = sData.posterImgName; // 图片名
      alink.click();
    };
    const reSelect = () => {
      localStorage.setItem("isSelected", 'reSelected');
      router.push({
        path: "/",
      });
    };
    const displayHaibao = () => {
      sData.haibaoShow = true;

      setTimeout(function () {
        createPoster();
      }, 3000);

      Toast.loading({
        message: "海报生成中请稍后...",
        forbidClick: true,
      });
    };
    const closeHaibao = () => {
      sData.haibaoShow = false;
      sData.haibaoUrl = "";
      sData.haibaoStatus = false;
    };
    const getWeChatData = async () => {
      try {
        var res = await axios({
          url: "https://api.lnomick.com/index.php/api/Wechat",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (res.data.code == "2000") {
          weChatData.appid = res.data.data.appid;
          console.log("res.data.data.appid", res.data.data.appid);
          console.log("weChatData.appid", weChatData.appid);
          weChatData.noncestr = res.data.data.noncestr;
          weChatData.timestamp = res.data.data.timestamp;
          weChatData.signature = res.data.data.signature;
          weChatData.url = res.data.data.url;
          weChatApi();
        } else {
          console.error("请求错误");
        }
      } catch (err) {
        console.error(err);
      }
    };
    const weChatApi = () => {
      var appId = weChatData?.appid;
      console.log("appId", appId);
      var noncestr = weChatData?.noncestr;
      var timestamp = weChatData?.timestamp;
      var signature = weChatData?.signature;
      var url = weChatData?.url;

      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: noncestr,
        signature: signature,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          success: function (res) {
            console.log(res);
          },
        });
        //分享给朋友
        wx.updateAppMessageShareData({
          title: "疫情缓解后，你最想做哪些事？", // 分享标题
          desc: "", // 分享描述
          link: "https://lswb.lnomick.com/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享给朋友成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        //分享到朋友圈
        wx.updateTimelineShareData({
          title: "疫情缓解后，你最想做哪些事？", // 分享标题
          link: "https://lswb.lnomick.com/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享到朋友圈成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        wx.error(function (res) {
          console.log("res", res);
        });
      });
    };
    onMounted(() => {
      document.title = '疫情缓解后，我最想做的三件事'
      sData.openid = Route.query.openid;
      if (sData.openid == null || sData.openid === "") {
        router.push({
          path: "/",
        });
      }
      sData.selectList = Route.query.selectList;
      console.log(sData.selectList);
      createQrcode("https://lswb.lnomick.com/");
      getSelect();
      getWeChatData();
    });
    return {
      createQrcode,
      sData,
      weChatData,
      createPoster,
      imgDown,
      getSelect,
      displayHaibao,
      closeHaibao,
      getPoster,
      weChatApi,
      getWeChatData,
      reSelect
    };
  },
};
</script>



<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
}
html,
body,
.bg {
  width: 100%;
  height: 100%;
  position: relative;
  background: url("../assets/images/bg4.jpg") center center no-repeat;
  background-size: 100% 100%;
  padding-top: 6rem;
  position: relative;
  .lslogo{
    position: absolute;
    height: 2rem;
    width: auto;
    top: 2rem;
    left: 2rem;
    img{
      height: 100%;
      width: auto;
    }
  }
  .center {
    width: 94%;
    margin: 0 auto;
    background: #fefdf8;
    border-radius: 1rem;
    padding: 0 5% 3rem 5%;
    .top {
      width: 100%;
      height: auto;
      position: relative;
      color: #010000;
      .headimg {
        width: 7rem;
        height: 7rem;
        margin: 0 auto;
        border-radius: 100%;
        transform: translateY(-50%);
        overflow: hidden;
        border: 2px solid red;
        margin-bottom: -3rem;
        img {
          width: 100%;
          height: auto;
        }
      }
      .nickname {
        text-align: center;
        font-size: 1.8rem;
      }
      .top-title {
        width: 100%;
        font-size: 1.8rem;
        margin-top: 1.5rem;
      }
      .top-title-e {
        width: 100%;
        font-size: 1.3rem;
        margin-top: 1rem;
      }
    }
  }
  .au-info{
    position: absolute;
    bottom: 1.5rem;
    left: 2rem;
    font-size: 1.3rem;
    color: #fefdf8;
    span{
      margin-left: 0.6rem;
    }
  }
}

.selectList {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .select-item {
    width: 29%;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.con {
  width: 100%;
  border-top: 1px dashed #5e597f;
  height: calc(100% - 32rem);
  padding-top: 3rem;
  .tips {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2;
    color: red;
    text-align: center;
  }
  .displayHaibao {
    width: 70%;
    margin: 0 auto;
    margin-top: 1.5rem;
    img{
      width: 100%;
      height: auto;
    }
  }
}
.share {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  .haibaoImg {
    width: 90%;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

#posterHtml {
  width: 90%;
  height: auto;
  background: #fefdf8;
  border-radius: 1rem;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: top;
  }
  .hb-top {
    width: 100%;
    padding: 2rem 5% 5px 5%;
    color: #010000;
    .hb-headimg {
      width: 7rem;
      height: 7rem;
      margin: 0 auto;
      border-radius: 100%;
      overflow: hidden;
      
      border: 2px solid red;
    }
    .hb-nickname {
      margin-top: 1rem;
      text-align: center;
      font-size: 1.8rem;
    }
    .hb-title {
      width: 100%;
      font-size: 1.8rem;
      margin-top: 1.5rem;
    }
    .hb-title2 {
      width: 100%;
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  }
  .hb-selectList {
    width: 100%;
    padding: 2rem 5%;
    margin-top: 2rem;
    background: #fff7d3;
    .hb-select-item {
      width: 30%;
      height: auto;
      float: left;
      margin-left: 5%;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  .hb-b {
    width: 100%;
    background: #fefdf8;
    padding: 2rem 2rem 0;
    .hb-b-l {
      width: calc(100% - 100px);
      height: auto;
      float: left;
      .hb-b-tips {
        text-align: right;
        color: #fa3d16;
        font-size: 1.4rem;
        line-height: 1.5;
        height: 55px;
      }
      .hb-b-logo {
        width: 140px;
        height: 47px;
        float: right;
      }
    }
    .hb-b-r {
      width: 80px;
      height: 80px;
      float: right;
      #qrcodeImg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.btn-group {
  width: 90%;
  height: 4rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .closeHaibao {
    width: 40%;
    height: 100%;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6rem;
    background: red;
    color: #fff;
  }
  .createHaibao {
    width: 40%;
    height: 100%;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6rem;
    background: blue;
    color: #fff;
  }
}
.haibao-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  padding-top: 40px;
}
</style>
