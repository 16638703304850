import { createStore } from 'vuex'

export default createStore({
  state: {
    muqinjieList:[]
  },
  getters: {
  },
  mutations: {
    updateMuqinjieList(state, value) {
      state.muqinjieList = value
      console.log("state.muqinjieList",state.muqinjieList)
    },
  },
  actions: {
  },
  modules: {
  }
})
