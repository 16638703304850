<template>
  <van-field
    v-model="fieldValue"
    is-link
    readonly
    label="地区"
    placeholder="请选择所在地区"
    @click="show = true"
  />
  <van-popup v-model:show="show" round position="bottom">
    <van-cascader
      v-model="cascaderValue"
      title="沈阳市常态化核酸检测采样点查询"
      :options="options"
      @close="show = false"
      @change="onChange"
      @finish="onFinish"
    />
  </van-popup>
  <div class="display" v-if="detail">
    <img :src="jiedaoList.imgUrl" />
  </div>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import axios from "axios";
import wx from "weixin-js-sdk";
export default {
  setup() {
    const detail = ref(false);
    const show = ref(false);
    const fieldValue = ref("");
    const cascaderValue = ref("");
    // 选项列表，children 代表子选项，支持多级嵌套
    const options = [
      {
        text: "和平区",
        value: "和平区",
        children: [
          { text: "太原街街道", value: "hp1" },
          { text: "北市场街道", value: "hp2" },
          { text: "浑河湾街道", value: "hp3" },
          { text: "马路湾街道", value: "hp4" },
          { text: "南市场街道", value: "hp5" },
          { text: "长白街道", value: "hp6" },
          { text: "新华街道", value: "hp7" },
          { text: "浑河站西街道", value: "hp8" },
          { text: "南湖街道", value: "hp9" },
          { text: "沈水湾街道", value: "hp10" },
        ],
      },
      {
        text: "沈河区",
        value: "沈河区",
        children: [
          { text: "东陵街道", value: "sh1" },
          { text: "泉园街道", value: "sh2" },
          { text: "马官桥街道", value: "sh3" },
          { text: "南塔街道", value: "sh4" },
          { text: "五里河街道", value: "sh5" },
          { text: "滨河街道", value: "sh6" },
          { text: "风雨坛街道", value: "sh7" },
          { text: "皇城街道", value: "sh12" },
          { text: "万莲街道", value: "sh8" },
          { text: "朱剪炉街道", value: "sh9" },
          { text: "北站街道", value: "sh10" },
          { text: "医疗机构", value: "sh11" },
        ],
      },
      {
        text: "铁西区",
        value: "铁西区",
        children: [
          { text: "霁虹街道", value: "tx1" },
          { text: "启工街道", value: "tx2" },
          { text: "笃工街道", value: "tx3" },
          { text: "工人村街道", value: "tx4" },
          { text: "兴顺街道", value: "tx5" },
          { text: "重工街道", value: "tx6" },
          { text: "凌空街道", value: "tx7" },
          { text: "兴华街道", value: "tx8" },
          { text: "昆明湖街道", value: "tx9" },
          { text: "大青街道", value: "tx10" },
          { text: "翟家街道", value: "tx11" },
          { text: "大潘街道", value: "tx12" },
          { text: "高花村街道", value: "tx13" },
          { text: "彰驿街道", value: "tx14" },
          { text: "新民屯街道", value: "tx15" },
          { text: "长滩街道", value: "tx16" },
          { text: "四方台街道", value: "tx17" },
        ],
      },
      {
        text: "皇姑区",
        value: "皇姑区",
        children: [
          { text: "北塔街道", value: "hg1" },
          { text: "陵东街道", value: "hg2" },
          { text: "三台子街道", value: "hg3" },
          { text: "鸭绿江街道", value: "hg4" },
          { text: "新乐街道", value: "hg5" },
          { text: "舍利塔街道", value: "hg6" },
          { text: "明廉街道", value: "hg7" },
          { text: "华山街道", value: "hg8" },
          { text: "黄河街道", value: "hg9" },
          { text: "四台子街道", value: "hg10" },
          { text: "其它", value: "hg11" },
        ],
      },
      {
        text: "大东区",
        value: "大东区",
        children: [
          { text: "长安街道", value: "dd1" },
          { text: "大北街道", value: "dd2" },
          { text: "东站街道", value: "dd3" },
          { text: "上园街道", value: "dd4" },
          { text: "津桥街道", value: "dd5" },
          { text: "文官街道", value: "dd6" },
          { text: "万泉街道", value: "dd7" },
          { text: "东塔街道", value: "dd8" },
          { text: "前进街道", value: "dd9" },
          { text: "二台子街道", value: "dd10" },
          { text: "其它", value: "dd11" },
        ],
      },
      {
        text: "浑南区",
        value: "浑南区",
        children: [
          { text: "五三街道", value: "hn1" },
          { text: "东湖街道", value: "hn2" },
          { text: "高坎街道", value: "hn3" },
          { text: "李相街道", value: "hn4" },
          { text: "王滨街道", value: "hn5" },
          { text: "祝家街道", value: "hn6" },
          { text: "桃仙街道", value: "hn7" },
          { text: "满堂街道", value: "hn8" },
          { text: "浑河站东街道", value: "hn9" },
          { text: "白塔街道", value: "hn10" },
        ],
      },
      {
        text: "于洪区",
        value: "于洪区",
        children: [
          { text: "平罗街道", value: "yh1" },
          { text: "沙岭街道", value: "yh2" },
          { text: "光辉街道", value: "yh3" },
          { text: "造化街道", value: "yh4" },
          { text: "迎宾路街道", value: "yh5" },
          { text: "马三家街道", value: "yh6" },
          { text: "城东湖街道", value: "yh7" },
          { text: "北陵街道", value: "yh8" },
          { text: "陵西街道", value: "yh9" },
          { text: "南阳湖街道", value: "yh10" },
        ],
      },
      {
        text: "沈北新区",
        value: "沈北新区",
        children: [
          { text: "正良街道", value: "sb1" },
          { text: "道义街道", value: "sb2" },
          { text: "虎石台街道", value: "sb3" },
          { text: "辉山街道", value: "sb4" },
          { text: "新城子街道", value: "sb5" },
          { text: "黄家街道", value: "sb6" },
          { text: "财落街道", value: "sb7" },
          { text: "马刚街道", value: "sb8" },
          { text: "清水街道", value: "sb9" },
          { text: "兴隆台街道", value: "sb10" },
        ],
      },
      {
        text: "苏家屯",
        value: "苏家屯",
        children: [
          { text: "解放街道", value: "sjt1" },
          { text: "民主街道", value: "sjt2" },
          { text: "中兴街道", value: "sjt3" },
          { text: "林盛", value: "sjt4" },
          { text: "永乐", value: "sjt5" },
          { text: "沙河", value: "sjt6" },
          { text: "陈相", value: "sjt7" },
          { text: "八一红菱", value: "sjt8" },
          { text: "十里河", value: "sjt9" },
          { text: "佟沟", value: "sjt10" },
          { text: "白清姚千", value: "sjt11" },
          { text: "沈水街道", value: "sjt12" },
   
        ],
      },

      {
        text: "新民市",
        value: "xm",
        children: [
          { text: "高台子镇", value: "xm1" },
          { text: "柳河沟镇", value: "xm2" },
          { text: "金五台子镇", value: "xm3" },
          { text: "红旗乡", value: "xm4" },
          { text: "大红旗镇", value: "xm5" },
          { text: "卢屯乡", value: "xm6" },
          { text: "梁山镇", value: "xm7" },
          { text: "姚堡乡", value: "xm8" },
          { text: "周坨子镇", value: "xm9" },
          { text: "大柳屯镇", value: "xm10" },
          { text: "于家乡", value: "xm11" },
          { text: "新农村镇", value: "xm12" },
          { text: "公主屯镇", value: "xm13" },
          { text: "前当堡镇", value: "xm14" },
          { text: "大民屯镇", value: "xm15" },
          { text: "东蛇子山镇", value: "xm16" },
          { text: "陶屯镇", value: "xm17" },
          { text: "罗家房镇", value: "xm18" },
          { text: "三道岗子镇", value: "xm19" },
          { text: "兴隆镇", value: "xm20" },
          { text: "兴隆堡镇", value: "xm21" },
          { text: "张屯镇", value: "xm22" },
          { text: "胡台镇", value: "xm23" },
          { text: "法哈牛镇", value: "xm24" },
          { text: "辽滨街道", value: "xm25" },
          { text: "新柳街道", value: "xm26" },
          { text: "东城街道", value: "xm27" },
          { text: "西城街道", value: "xm28" },
          
        ],
      },
      {
        text: "辽中区",
        value: "lz",
      },
      {
        text: "法库县",
        value: "fk",
      },
      {
        text: "康平县",
        value: "kp",
      },
    ];
    const jiedaoList = reactive({
      imgUrl: "",
      imgList: [
        {
          id: "tx1",
          url: "https://img.lnomick.com/ls/tx1.jpg",
        },
        {
          id: "tx2",
          url: "https://img.lnomick.com/ls/tx2.jpg",
        },
        {
          id: "tx3",
          url: "https://img.lnomick.com/ls/tx3.jpg",
        },
        {
          id: "tx4",
          url: "https://img.lnomick.com/ls/tx4.jpg",
        },
        {
          id: "tx5",
          url: "https://img.lnomick.com/ls/tx5.jpg",
        },
        {
          id: "tx6",
          url: "https://img.lnomick.com/ls/tx6.jpg",
        },
        {
          id: "tx7",
          url: "https://img.lnomick.com/ls/tx7.jpg",
        },
        {
          id: "tx8",
          url: "https://img.lnomick.com/ls/tx8.jpg",
        },
        {
          id: "tx9",
          url: "https://img.lnomick.com/ls/tx9.jpg",
        },
        {
          id: "tx10",
          url: "https://img.lnomick.com/ls/tx10.jpg",
        },
        {
          id: "tx11",
          url: "https://img.lnomick.com/ls/tx11.jpg",
        },
        {
          id: "tx12",
          url: "https://img.lnomick.com/ls/tx12.jpg",
        },
        {
          id: "tx13",
          url: "https://img.lnomick.com/ls/tx13.jpg",
        },
        {
          id: "tx14",
          url: "https://img.lnomick.com/ls/tx14.jpg",
        },
        {
          id: "tx15",
          url: "https://img.lnomick.com/ls/tx15.jpg",
        },
        {
          id: "tx16",
          url: "https://img.lnomick.com/ls/tx16.jpg",
        },
        {
          id: "tx17",
          url: "https://img.lnomick.com/ls/tx17.jpg",
        },
        {
          id: "hg1",
          url: "https://img.lnomick.com/ls/hg1.jpg",
        },
        {
          id: "hg2",
          url: "https://img.lnomick.com/ls/hg2.jpg",
        },
        {
          id: "hg3",
          url: "https://img.lnomick.com/ls/hg3.jpg",
        },
        {
          id: "hg4",
          url: "https://img.lnomick.com/ls/hg4.jpg",
        },
        {
          id: "hg5",
          url: "https://img.lnomick.com/ls/hg5.jpg",
        },
        {
          id: "hg6",
          url: "https://img.lnomick.com/ls/hg6.jpg",
        },
        {
          id: "hg7",
          url: "https://img.lnomick.com/ls/hg7.jpg",
        },
        {
          id: "hg8",
          url: "https://img.lnomick.com/ls/hg8.jpg",
        },
        {
          id: "hg9",
          url: "https://img.lnomick.com/ls/hg9.jpg",
        },
        {
          id: "hg10",
          url: "https://img.lnomick.com/ls/hg10.jpg",
        },
        {
          id: "hg11",
          url: "https://img.lnomick.com/ls/hg11.jpg",
        },
        {
          id: "hp1",
          url: "https://img.lnomick.com/ls/hp1.jpg",
        },
        {
          id: "hp2",
          url: "https://img.lnomick.com/ls/hp2.jpg",
        },
        {
          id: "hp3",
          url: "https://img.lnomick.com/ls/hp3.jpg",
        },
        {
          id: "hp4",
          url: "https://img.lnomick.com/ls/hp4.jpg",
        },
        {
          id: "hp5",
          url: "https://img.lnomick.com/ls/hp5.jpg",
        },
        {
          id: "hp6",
          url: "https://img.lnomick.com/ls/hp6.jpg",
        },
        {
          id: "hp7",
          url: "https://img.lnomick.com/ls/hp7.jpg",
        },
        {
          id: "hp8",
          url: "https://img.lnomick.com/ls/hp8.jpg",
        },
        {
          id: "hp9",
          url: "https://img.lnomick.com/ls/hp9.jpg",
        },
        {
          id: "hp10",
          url: "https://img.lnomick.com/ls/hp10.jpg",
        },
        {
          id: "sh1",
          url: "https://img.lnomick.com/ls/sh1.jpg",
        },
        {
          id: "sh2",
          url: "https://img.lnomick.com/ls/sh2.jpg",
        },
        {
          id: "sh3",
          url: "https://img.lnomick.com/ls/sh3.jpg",
        },
        {
          id: "sh4",
          url: "https://img.lnomick.com/ls/sh4.jpg",
        },
        {
          id: "sh5",
          url: "https://img.lnomick.com/ls/sh5.jpg",
        },
        {
          id: "sh6",
          url: "https://img.lnomick.com/ls/sh6.jpg",
        },
        {
          id: "sh7",
          url: "https://img.lnomick.com/ls/sh7.jpg",
        },
        {
          id: "sh8",
          url: "https://img.lnomick.com/ls/sh8.jpg",
        },
        {
          id: "sh9",
          url: "https://img.lnomick.com/ls/sh9.jpg",
        },
        {
          id: "sh10",
          url: "https://img.lnomick.com/ls/sh10.jpg",
        },
        {
          id: "sh11",
          url: "https://img.lnomick.com/ls/sh11.jpg",
        },
        {
          id: "sh12",
          url: "https://img.lnomick.com/ls/sh12.jpg",
        },
        {
          id: "dd1",
          url: "https://img.lnomick.com/ls/dd1.jpg",
        },
        {
          id: "dd2",
          url: "https://img.lnomick.com/ls/dd2.jpg",
        },
        {
          id: "dd3",
          url: "https://img.lnomick.com/ls/dd3.jpg",
        },
        {
          id: "dd4",
          url: "https://img.lnomick.com/ls/dd4.jpg",
        },
        {
          id: "dd5",
          url: "https://img.lnomick.com/ls/dd5.jpg",
        },
        {
          id: "dd6",
          url: "https://img.lnomick.com/ls/dd6.jpg",
        },
        {
          id: "dd7",
          url: "https://img.lnomick.com/ls/dd7.jpg",
        },
        {
          id: "dd8",
          url: "https://img.lnomick.com/ls/dd8.jpg",
        },
        {
          id: "dd9",
          url: "https://img.lnomick.com/ls/dd9.jpg",
        },
        {
          id: "dd10",
          url: "https://img.lnomick.com/ls/dd10.jpg",
        },
        {
          id: "dd11",
          url: "https://img.lnomick.com/ls/dd11.jpg",
        },
        {
          id: "hn1",
          url: "https://img.lnomick.com/ls/hn1.jpg",
        },
        {
          id: "hn2",
          url: "https://img.lnomick.com/ls/hn2.jpg",
        },
        {
          id: "hn3",
          url: "https://img.lnomick.com/ls/hn3.jpg",
        },
        {
          id: "hn4",
          url: "https://img.lnomick.com/ls/hn4.jpg",
        },
        {
          id: "hn5",
          url: "https://img.lnomick.com/ls/hn5.jpg",
        },
        {
          id: "hn6",
          url: "https://img.lnomick.com/ls/hn6.jpg",
        },
        {
          id: "hn7",
          url: "https://img.lnomick.com/ls/hn7.jpg",
        },
        {
          id: "hn8",
          url: "https://img.lnomick.com/ls/hn8.jpg",
        },
        {
          id: "hn9",
          url: "https://img.lnomick.com/ls/hn9.jpg",
        },
        {
          id: "hn10",
          url: "https://img.lnomick.com/ls/hn10.jpg",
        },
        {
          id: "yh1",
          url: "https://img.lnomick.com/ls/yh1.jpg",
        },
        {
          id: "yh2",
          url: "https://img.lnomick.com/ls/yh2.jpg",
        },
        {
          id: "yh3",
          url: "https://img.lnomick.com/ls/yh3.jpg",
        },
        {
          id: "yh4",
          url: "https://img.lnomick.com/ls/yh4.jpg",
        },
        {
          id: "yh5",
          url: "https://img.lnomick.com/ls/yh5.jpg",
        },
        {
          id: "yh6",
          url: "https://img.lnomick.com/ls/yh6.jpg",
        },
        {
          id: "yh7",
          url: "https://img.lnomick.com/ls/yh7.jpg",
        },
        {
          id: "yh8",
          url: "https://img.lnomick.com/ls/yh8.jpg",
        },
        {
          id: "yh9",
          url: "https://img.lnomick.com/ls/yh9.jpg",
        },
        {
          id: "yh10",
          url: "https://img.lnomick.com/ls/yh10.jpg",
        },
        {
          id: "xm1",
          url: "https://img.lnomick.com/ls/xm1.jpg",
        },
        {
          id: "xm2",
          url: "https://img.lnomick.com/ls/xm2.jpg",
        },
        {
          id: "xm3",
          url: "https://img.lnomick.com/ls/xm3.jpg",
        },
        {
          id: "xm4",
          url: "https://img.lnomick.com/ls/xm4.jpg",
        },
        {
          id: "xm5",
          url: "https://img.lnomick.com/ls/xm5.jpg",
        },
        {
          id: "xm6",
          url: "https://img.lnomick.com/ls/xm6.jpg",
        },
        {
          id: "xm7",
          url: "https://img.lnomick.com/ls/xm7.jpg",
        },
        {
          id: "xm8",
          url: "https://img.lnomick.com/ls/xm8.jpg",
        },
        {
          id: "xm9",
          url: "https://img.lnomick.com/ls/xm9.jpg",
        },
        {
          id: "xm10",
          url: "https://img.lnomick.com/ls/xm10.jpg",
        },
        {
          id: "xm11",
          url: "https://img.lnomick.com/ls/xm11.jpg",
        },
        {
          id: "xm12",
          url: "https://img.lnomick.com/ls/xm12.jpg",
        },
        {
          id: "xm13",
          url: "https://img.lnomick.com/ls/xm13.jpg",
        },
        {
          id: "xm14",
          url: "https://img.lnomick.com/ls/xm14.jpg",
        },
        {
          id: "xm15",
          url: "https://img.lnomick.com/ls/xm15.jpg",
        },
        {
          id: "xm16",
          url: "https://img.lnomick.com/ls/xm16.jpg",
        },
        {
          id: "xm17",
          url: "https://img.lnomick.com/ls/xm17.jpg",
        },
        {
          id: "xm18",
          url: "https://img.lnomick.com/ls/xm18.jpg",
        },
        {
          id: "xm19",
          url: "https://img.lnomick.com/ls/xm19.jpg",
        },
        {
          id: "xm20",
          url: "https://img.lnomick.com/ls/xm20.jpg",
        },
        {
          id: "xm21",
          url: "https://img.lnomick.com/ls/xm21.jpg",
        },
        {
          id: "xm22",
          url: "https://img.lnomick.com/ls/xm22.jpg",
        },
        {
          id: "xm23",
          url: "https://img.lnomick.com/ls/xm23.jpg",
        },
        {
          id: "xm24",
          url: "https://img.lnomick.com/ls/xm24.jpg",
        },
        {
          id: "xm25",
          url: "https://img.lnomick.com/ls/xm25.jpg",
        },
        {
          id: "xm26",
          url: "https://img.lnomick.com/ls/xm26.jpg",
        },
        {
          id: "xm27",
          url: "https://img.lnomick.com/ls/xm27.jpg",
        },
        {
          id: "xm28",
          url: "https://img.lnomick.com/ls/xm28.jpg",
        },
        {
          id: "kp",
          url: "https://img.lnomick.com/ls/kp.jpg",
        },
        {
          id: "fk",
          url: "https://img.lnomick.com/ls/fk.jpg",
        },
        {
          id: "lz",
          url: "https://img.lnomick.com/ls/lz.jpg",
        },
        {
          id: "sb1",
          url: "https://img.lnomick.com/ls/sb1.jpg",
        },
        {
          id: "sb2",
          url: "https://img.lnomick.com/ls/sb2.jpg",
        },
        {
          id: "sb3",
          url: "https://img.lnomick.com/ls/sb3.jpg",
        },
        {
          id: "sb4",
          url: "https://img.lnomick.com/ls/sb4.jpg",
        },
        {
          id: "sb5",
          url: "https://img.lnomick.com/ls/sb5.jpg",
        },
        {
          id: "sb6",
          url: "https://img.lnomick.com/ls/sb6.jpg",
        },
        {
          id: "sb7",
          url: "https://img.lnomick.com/ls/sb7.jpg",
        },
        {
          id: "sb8",
          url: "https://img.lnomick.com/ls/sb8.jpg",
        },
        {
          id: "sb9",
          url: "https://img.lnomick.com/ls/sb9.jpg",
        },
        {
          id: "sb10",
          url: "https://img.lnomick.com/ls/sb10.jpg",
        },
        {
          id: "sjt1",
          url: "https://img.lnomick.com/ls/sjt1.jpg",
        },
        {
          id: "sjt2",
          url: "https://img.lnomick.com/ls/sjt2.jpg",
        },
        {
          id: "sjt3",
          url: "https://img.lnomick.com/ls/sjt3.jpg",
        },
        {
          id: "sjt4",
          url: "https://img.lnomick.com/ls/sjt4.jpg",
        },
        {
          id: "sjt5",
          url: "https://img.lnomick.com/ls/sjt5.jpg",
        },
        {
          id: "sjt6",
          url: "https://img.lnomick.com/ls/sjt6.jpg",
        },
        {
          id: "sjt7",
          url: "https://img.lnomick.com/ls/sjt7.jpg",
        },
        {
          id: "sjt8",
          url: "https://img.lnomick.com/ls/sjt8.jpg",
        },
        {
          id: "sjt9",
          url: "https://img.lnomick.com/ls/sjt9.jpg",
        },
        {
          id: "sjt10",
          url: "https://img.lnomick.com/ls/sjt10.jpg",
        },
        {
          id: "sjt11",
          url: "https://img.lnomick.com/ls/sjt11.jpg",
        },
        {
          id: "sjt12",
          url: "https://img.lnomick.com/ls/sjt12.jpg",
        },
      ],
    });
    const weChatData = reactive({
      appid: "",
      noncestr: "",
      timestamp: 0,
      signature: "",
      url: "",
    });
    // 全部选项选择完毕后，会触发 finish 事件
    const onFinish = ({ value }) => {
      jiedaoList.imgList.forEach((item) => {
        if (item.id == value) {
          jiedaoList.imgUrl = item.url;
        }
      });
      show.value = false;
      detail.value = true;
      fieldValue.value = value;
    };
    const onChange = () => {
      detail.value = false;
    };
    const getWeChatData = async () => {
      try {
        var res = await axios({
          url: "https://api.lnomick.com/index.php/api/Wechat2",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (res.data.code == "2000") {
          weChatData.appid = res.data.data.appid;
          console.log("res.data.data.appid", res.data.data.appid);
          console.log("weChatData.appid", weChatData.appid);
          weChatData.noncestr = res.data.data.noncestr;
          weChatData.timestamp = res.data.data.timestamp;
          weChatData.signature = res.data.data.signature;
          weChatData.url = res.data.data.url;
          weChatApi();
        } else {
          console.error("请求错误");
        }
      } catch (err) {
        console.error(err);
      }
    };
    const weChatApi = () => {
      var appId = weChatData?.appid;
      var noncestr = weChatData?.noncestr;
      var timestamp = weChatData?.timestamp;
      var signature = weChatData?.signature;
      var url = weChatData?.url;

      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: noncestr,
        signature: signature,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          success: function (res) {
            console.log(res);
          },
        });
        //分享给朋友
        wx.updateAppMessageShareData({
          title: "不用翻页！快速查询沈阳13区县（市）常态化核酸检测采样点", // 分享标题
          desc: "", // 分享描述
          link: "https://lswb.lnomick.com/hesuan", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享给朋友成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        //分享到朋友圈
        wx.updateTimelineShareData({
          title: "不用翻页！快速查询沈阳13区县（市）常态化核酸检测采样点", // 分享标题
          link: "https://lswb.lnomick.com/hesuan", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享到朋友圈成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        wx.error(function (res) {
          console.log("res", res);
        });
      });
    };
    onMounted(() => {
      document.title = "不用翻页！快速查询沈阳13区县（市）常态化核酸检测采样点";
      getWeChatData();
    });

    return {
      show,
      options,
      detail,
      onFinish,
      fieldValue,
      cascaderValue,
      jiedaoList,
      onChange,
      weChatApi,
      getWeChatData,
      weChatData,
    };
  },
};
</script>

<style lang="scss" scoped>
.display {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>