import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    Badge
} from 'vant'
import {
    Icon
} from 'vant';
import {
    Toast
} from 'vant';
import {
    Cascader
} from 'vant';
import {
    Circle
} from 'vant';
import 'vant/lib/index.css';
import 'animate.css/animate.min.css';
import 'default-passive-events'
import 'animate.css';

//import vConsole from '@/utils/vconsole.js'



let loadingUrls = [
    
    'https://img.lnomick.com/ls/ditie/page2-bg.jpg',

]

// 预加载 加载页资源
loadingUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
        img.style.display = 'none';
        document.body.appendChild(img);
        console.log('imgurl', url)
    };
});






const app = createApp(App);
app.use(Badge);
app.use(Icon);
app.use(Toast);
app.use(Cascader);
app.use(Circle);


app.use(store).use(router).mount('#app')