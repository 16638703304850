<template>
  <div class="bg">
    <div class="lslogo">
      <img src="https://img.lnomick.com/ls/lslogo3.png" />
    </div>
    <div class="center">
      <div class="top">
        <div class="top-title">
          <img :src="iData.headimgurl" class="headimg" />
          <div class="head-title">疫情结束后</div>
        </div>
        <div class="top-title2">
          <div class="head-title2">选出你最想做的三件事</div>
        </div>
        <div class="top-title3">
          <div class="steep-item">
            <div class="steep-t">Step1</div>
            <div class="steep-b">选择三件事</div>
          </div>
          <div class="steep-item">
            <div class="steep-t">Step2</div>
            <div class="steep-b">生成海报图片</div>
          </div>
          <div class="steep-item">
            <div class="steep-t">Step3</div>
            <div class="steep-b">转发让更多人参与</div>
          </div>
        </div>
      </div>
      <div class="con">
        <div
          class="img-item"
          v-for="(item, index) in iData.imgList"
          :key="index"
          @click="select(index)"
        >
          <img :src="item.imgUrl" />
        </div>
      </div>
    </div>
    <div class="fix-b" v-if="iData.selectList.length > 0">
      <div class="select-list">
        <div class="select-item">
          <img :src="iData.selectList[0].imgUrl" />
          <div class="del" @click="del(0)">
            <van-icon name="cross" color="#fff" size="10" />
          </div>
        </div>

        <div class="select-item">
          <img :src="iData.selectList[1].imgUrl" v-if="iData.selectList[1]" />
          <div class="del" v-if="iData.selectList[1]" @click="del(1)">
            <van-icon name="cross" color="#fff" size="10" />
          </div>
        </div>
        <div class="select-item">
          <img :src="iData.selectList[2].imgUrl" v-if="iData.selectList[2]" />
          <div class="del" v-if="iData.selectList[2]" @click="del(2)">
            <van-icon name="cross" color="#fff" size="10" />
          </div>
        </div>
      </div>
      <div class="select-info">
        <div class="selected">已选{{ iData.selectList.length }}/3</div>
        <div
          class="select-btn"
          v-if="iData.selectList.length == 3"
          @click="sub"
        >
          确定
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import axios from "axios";
import { useRouter } from "vue-router";
import { onMounted, reactive } from "vue";
import wx from "weixin-js-sdk";
export default {
  setup() {
    const router = useRouter();
    const iData = reactive({
      code: "",

      appid: "wx81452b9e1a80c625",
      imgList: [
        {
          index: 0,
          imgUrl: require("@/assets/images/want1.jpg"),
        },
        {
          index: 1,
          imgUrl: require("@/assets/images/want2.jpg"),
        },
        {
          index: 2,
          imgUrl: require("@/assets/images/want3.jpg"),
        },
        {
          index: 3,
          imgUrl: require("@/assets/images/want4.jpg"),
        },
        {
          index: 4,
          imgUrl: require("@/assets/images/want5.jpg"),
        },
        {
          index: 5,
          imgUrl: require("@/assets/images/want6.jpg"),
        },
        {
          index: 6,
          imgUrl: require("@/assets/images/want7.jpg"),
        },
        {
          index: 7,
          imgUrl: require("@/assets/images/want8.jpg"),
        },
        {
          index: 8,
          imgUrl: require("@/assets/images/want9.jpg"),
        },
        {
          index: 9,
          imgUrl: require("@/assets/images/want10.jpg"),
        },
        {
          index: 10,
          imgUrl: require("@/assets/images/want11.jpg"),
        },
        {
          index: 11,
          imgUrl: require("@/assets/images/want12.jpg"),
        },
        {
          index: 12,
          imgUrl: require("@/assets/images/want13.jpg"),
        },
        {
          index: 13,
          imgUrl: require("@/assets/images/want14.jpg"),
        },
        {
          index: 14,
          imgUrl: require("@/assets/images/want15.jpg"),
        },
      ],
      selectList: [],
      nickname: localStorage.getItem("nickname") || "",
      openid: localStorage.getItem("openid") || "",
      headimgurl: localStorage.getItem("headimgurl") || "",
    });
    const weChatData = reactive({
      appid: "",
      noncestr: "",
      timestamp: 0,
      signature: "",
      url: "",
    });
    const select = (imgIndex) => {
      //console.log(imgIndex);
      var isSelect = false;
      //console.log("iDataimgList[imgIndex]", iData.imgList[imgIndex]);
      if (iData.selectList.length < 3) {
        for (let i = 0; i < iData.selectList.length; i++) {
          if (iData.imgList[imgIndex].index == iData.selectList[i].index) {
            isSelect = true;
            break;
          }
        }
        if (isSelect) {
          console.log("该图片已经被选中了");
        } else {
          iData.selectList = [...iData.selectList, iData.imgList[imgIndex]];
        }
      } else {
        console.log("已经选了3项了");
      }
      //console.log(" selectList", iData.selectList);
    };
    const del = (index) => {
      iData.selectList.splice(index, 1);
      console.log("selectList", iData.selectList);
    };
    const getCode = () => {
      const code = getUrlParam("code"); //是否存在code
      let local = encodeURIComponent(window.location.href); //获取当前url

      if (code == null || code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${iData.appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect`;
      } else {
        if (iData.nickname == null || iData.nickname === "") {
          let postData = {
            code: iData.code,
          };
          axios({
            url: "https://api.lnomick.com/index.php/api/snsapi_userinfo",
            method: "post",
            header: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: qs.stringify(postData),
          })
            .then((res) => {
              if (res.data.code == "2000") {
                iData.nickname = res.data.data.nickname;
                iData.headimgurl = res.data.data.headimgurl;
                iData.openid = res.data.data.openid;
                localStorage.setItem("nickname", res.data.data.nickname);
                localStorage.setItem("headimgurl", res.data.data.headimgurl);
                localStorage.setItem("openid", res.data.data.openid);
                //js-sdk
                getWeChatData();
              } else {
                alert(res.data.msg);
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          alert("已经获取用户数据了");
        }
      }
    };
    const getUrlParam = (name) => {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var rrr = decodeURIComponent(window.location.search);
      var r = rrr.substr(1).match(reg);
      if (r != null) {
        iData.code = unescape(r[2]);
        //localStorage.setItem("code",unescape(r[2]))
        return unescape(r[2]);
      }

      return null;
    };

    const sub = async () => {
      try {
        const postData = {
          select1: iData.selectList[0].index,
          select2: iData.selectList[1].index,
          select3: iData.selectList[2].index,
          openid: iData.openid,
          nickname: iData.nickname,
          headimgurl: iData.headimgurl,
        };
        const result = await axios({
          url: "https://api.lnomick.com/index.php/api/WantSelect",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(postData),
        });
        if (result.data.code == "2000") {
          localStorage.setItem("isSelected", "isSelected");
          router.push({
            path: "/share",
            query: {
              openid: iData.openid,
            },
          });
        }
      } catch (error) {
        alert(error);
      }
    };
    const getWeChatData = async () => {
      try {
        var res = await axios({
          url: "https://api.lnomick.com/index.php/api/Wechat",
          method: "post",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (res.data.code == "2000") {
          weChatData.appid = res.data.data.appid;

          weChatData.noncestr = res.data.data.noncestr;
          weChatData.timestamp = res.data.data.timestamp;
          weChatData.signature = res.data.data.signature;
          weChatData.url = res.data.data.url;
          weChatApi();
        } else {
          console.error("请求错误");
        }
      } catch (err) {
        console.error(err);
      }
    };
    const weChatApi = () => {
      var appId = weChatData?.appid;
      var noncestr = weChatData?.noncestr;
      var timestamp = weChatData?.timestamp;
      var signature = weChatData?.signature;
      var url = weChatData?.url;

      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: noncestr,
        signature: signature,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
      });

      wx.ready(function () {
        //分享给朋友
        wx.updateAppMessageShareData({
          title: "疫情缓解后，你最想做哪些事？", // 分享标题
          desc: "", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享给朋友成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });

        //分享到朋友圈
        wx.updateTimelineShareData({
          title: "疫情缓解后，你最想做哪些事？", // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://img.lnomick.com/ls/lslogo2.jpg", // 分享图标
          success: function () {
            // 设置成功
            console.log("分享到朋友圈成功");
          },
          fail: function (fail) {
            console.log(fail);
          },
        });
      });
    };
    onMounted(() => {
      document.title = '疫情缓解后，你最想做哪些事？'
      const isSelected = localStorage.getItem("isSelected");
      console.log("isSelected", isSelected);
      //如果选完了就判断缓存跳转
      if (isSelected == "isSelected") {
        console.log("isSelected2", isSelected);

        router.push({
          path: "/share",
          query: {
            openid: iData.openid,
          },
        });
      }
      if (iData.openid == null || iData.openid === "") {
        getCode();
      } else {
        getWeChatData();
        console.log("有缓存，无需重新授权");
      }
    });
    return {
      iData,
      weChatData,
      select,
      del,
      getCode,
      getUrlParam,
      weChatApi,
      getWeChatData,
      sub,
    };
  },
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
}

.bg {
  width: 100%;
  background: url("../assets/images/bg4.jpg") center center no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  z-index: 200;

  .lslogo {
    position: absolute;
    height: 2rem;
    width: auto;
    top: 2rem;
    left: 2rem;
    img {
      height: 100%;
      width: auto;
    }
  }
  .center {
    width: 90%;
    margin: 0 auto;
    background: #fff;
    .top {
      width: 100%;
      height: auto;
      padding: 3rem 2rem;
      .top-title {
        height: 7rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 3px solid #333;
        .headimg {
          width: 5rem;
          height: 5rem;
          border-radius: 100%;
          overflow: hidden;
        }
        .head-title {
          font-size: 2.4rem;
          color: #333;
          font-weight: 700;
          margin-left: 2rem;
        }
      }
      .top-title2 {
        width: 100%;
        height: 6rem;
        border-bottom: 3px solid #333;
        .head-title2 {
          line-height: 6rem;
          font-size: 2.6rem;
          color: #333;
          font-weight: 700;
        }
      }
      .top-title3 {
        width: 100%;
        height: 5rem;
        border-bottom: 3px solid #333;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .steep-item {
          width: 33.3%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border-right: 1px dashed #000;
          padding-left: 0.5rem;
          &:nth-child(3) {
            border: 0;
            width: 40%;
          }
          &:nth-child(2) {
            width: 33%;
          }
          &:nth-child(1) {
            width: 27%;
          }

          .steep-t {
            color: #000000;
            font-size: 12px;
            transform: scale(0.9);
            transform-origin: left center;
          }
          .steep-b {
            color: #000000;
            font-size: 12px;
            margin-top: 0.8rem;
            transform: scale(0.9);
            transform-origin: left center;
          }
        }
      }
    }
    .con {
      width: 100%;
      
      background: #fff7d3;
      padding: 0 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      
      .img-item {
        width: 30%;
        margin-top: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 330px) {
  .steep-item {
    width: 33.3%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px dashed #000;
    padding-left: 0.5rem;
    &:nth-child(3) {
      border: 0;
      width: 40%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(1) {
      width: 25%;
    }
  }
}
.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}
.fix-b {
  width: 100%;
  padding: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #fffdf6;
  position: sticky;
  bottom: 0;
  left: 0;
  .select-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-item {
      width: 30%;
      height: auto;
      background: #ebebeb;
      position: relative;
      .del {
        position: absolute;
        right: -5px;
        top: -5px;
        background: #fd561f;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .select-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .selected {
      color: #444;
      font-size: 12px;
      letter-spacing: 1px;
    }
    .select-btn {
      width: 120px;
      height: 40px;
      border-radius: 30px;
      background: #fd561f;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }
}
</style>
