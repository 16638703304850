import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/index.vue'
import Share from '../views/share.vue'
import Hesuan from '../views/hesuan.vue'
import H5_4_14 from '../views/h5-4-14.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },{
    path: '/share',
    name: 'share',
    component: Share
  },{
    path: '/hesuan',
    name: 'hesuan',
    component: Hesuan
  }
  ,{
    path: '/h5-4-14',
    name: 'H5_4_14',
    component: H5_4_14
  },{
    path: '/yimiao',
    name: 'yimiao',
    component: () => import('@/views/oldPerson.vue')
  },{
    path: '/yimiao2',
    name: 'yimiao2',
    component: () => import('@/views/yimiao.vue')
  },{
    path: '/yimiao3',
    name: 'yimiao3',
    component: () => import('@/views/yimiao3.vue')
  },{
    path: '/liaolan',
    name: 'liaolan',
    component: () => import('@/views/liaolan.vue')
  },{
    path: '/muqinjie',
    name: 'muqinjie',
    component: () => import('@/views/muqinjie/index.vue')
  },{
    path: '/mqj-select',
    name: 'mqj-select',
    component: () => import('@/views/muqinjie/select.vue')
  },{
    path: '/mqj-share',
    name: 'mqj-share',
    component: () => import('@/views/muqinjie/share.vue')
  },{
    path: '/gaokao',
    name: 'gaokao',
    component: () => import('@/views/gaokao/index.vue')
  },{
    path: '/child',
    name: 'child',
    component: () => import('@/views/child/index.vue')
  },{
    path: '/father',
    name: 'father',
    component: () => import('@/views/father/index.vue')
  },{
    path: '/leifeng/index',
    name: 'leifeng',
    component: () => import('@/views/leifeng/index.vue')
  },{
    path: '/leifeng/preload',
    name: 'preload',
    component: () => import('@/views/leifeng/preload.vue')
  },{
    path: '/leifeng/entrance',
    name: 'entrance',
    component: () => import('@/views/leifeng/entrance.vue')
  },{
    path: '/918',
    name: '918',
    component: () => import('@/views/918/index.vue')
  },{
    path: '/918/map',
    name: 'map',
    component: () => import('@/views/918/map.vue')
  },{
    path: '/918/cg1',
    name: 'cg1',
    component: () => import('@/views/918/cg1.vue')
  },{
    path: '/918/cg2',
    name: 'cg2',
    component: () => import('@/views/918/cg2.vue')
  },{
    path: '/918/cg3',
    name: 'cg3',
    component: () => import('@/views/918/cg3.vue')
  },{
    path: '/918/cg4',
    name: 'cg4',
    component: () => import('@/views/918/cg4.vue')
  },{
    path: '/918/jidian',
    name: 'jidian',
    component: () => import('@/views/918/jidian.vue')
  },{
    path: '/ditie/index',
    name: 'ditie',
    component: () => import('@/views/ditie/index.vue')
  }
  ,{
    path: '/AI/index',
    name: 'AI',
    component: () => import('@/views/AI/index.vue')
  },{
    path: '/2024yd/index',
    name: 'yuandan',
    component: () => import('@/views/2024yd/index.vue')
  },{
    path: '/2024chunjie/index',
    name: 'chunjie',
    component: () => import('@/views/2024chunjie/index.vue')
  },{
    path: '/2024chunjie/planb',
    name: 'chunjieplanb',
    component: () => import('@/views/2024chunjie/planb.vue')
  },{
    path: '/2024chunjie/planc',
    name: 'chunjieplanc',
    component: () => import('@/views/2024chunjie/planc.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
